@import "~reset-css/sass/_reset.scss";
@import "~sheng/sheng.scss";
@import url("https://fonts.googleapis.com/css?family=Catamaran:400,400i,700|PT+Sans:400,700&display=swap");

$heading-font: "PT Sans", sans-serif;
$body-font: Catamaran, sans-serif;

// Heading colours are WCAG AA Large compatible, generated by:
// https://accessible-colors.com/

$wattle-leaf: #00b159;
$wattle-leaf-headings: #00ab56;
$wattle-flower: #ffc518;
$wattle-flower-headings: #bd8d00;
$desert-sand: #f36f3a;
$desert-sand-headings: #f36b35;
$red-centre: #e01d43;
$lorikeet-blue: #5b57a5;

@mixin app-mode {
  @media (max-width: 767.9999px) {
    html.schedule & {
      @content;
    }
  }
}

body {
  font-family: $body-font;
}

main {
  @include sheng();
  %sheng-heading {
    font-family: $heading-font;
  }
  max-width: 33em;
  margin: 0 auto;
  padding: 0 20px;
  color: hsla(0, 0, 0, 0.65);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: hsla(0, 0, 0, 0.85);
  }
  z-index: 1;
  blockquote {
    border-left: 5px solid hsla(0, 0, 0, 0.2);
    padding-left: 10px;
  }

  img {
    max-width: 100%;
  }

  i,
  em {
    background-color: rgba(255, 239, 97, 0.4);
    padding: -3px 3px;
  }
}

$logo-w-ratio: 3.577;

header {
  background-color: $wattle-leaf;
  background-image: url("duotone.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 49%;
  --logo-h: 150px;
  overflow: hidden;
  padding-bottom: 50px;

  @media only screen and (max-width: 500px) {
    --logo-h: 100px;
  }

  img {
    height: calc(var(--logo-h));
    .debug & {
      border: 1px solid magenta;
    }
  }
  .rotated {
    transform-origin: calc(var(--logo-h) * #{$logo-w-ratio}) top;
    transform: rotate(-5deg);
    .debug & {
      border: 1px solid magenta;
    }
  }
  nav {
    background-color: white;
    padding: 320px;
    margin: -300px;
    padding-top: 0;
    margin-top: 0;
  }

  article {
    font-size: 50px;
    color: white;
    font-family: $heading-font;
    font-weight: bold;
    margin: 50px;
    margin-top: 0;
    max-width: 700px;

    @media only screen and (max-width: 720px) {
      font-size: 30px;
    }

    @media only screen and (max-width: 400px) {
      font-size: 20px;
    }

    a {
      color: inherit;
      text-decoration: none;
      border: 0.12em solid white;
      border-radius: 0.36em;
      font-size: 0.5em;
      padding: 0.08em 0.6em;

      &:hover {
        background-color: white;
        color: $wattle-leaf;
      }
    }
  }
}

nav {
  font-family: $heading-font;
  font-weight: bold;
  font-size: 24px;

  display: grid;
  grid-template:
    "logo nav curlyboi" auto
    "dates dates dates" auto / min-content auto 1fr;
  &.home {
    grid-template:
      "nav curlyboi" auto
      "dates dates" auto / auto 1fr;
  }

  &:before {
    grid-area: curlyboi;
    content: "";
    font-size: 1px;
    background-image: url("Curlyboi.svg");
    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: center left;
    margin-left: 20px;
  }

  &.home {
    #nav-logo {
      display: none;
    }
  }

  #nav-logo {
    grid-area: logo;
    margin: 0;
    padding: 0;
    display: flex;
    img {
      height: calc(1em + 40px);
    }
  }

  ul {
    grid-area: nav;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
  }

  li {
    display: inline-block;
    padding: 20px;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &:nth-child(1) {
      color: $wattle-leaf-headings;
    }
    &:nth-child(2) {
      color: $wattle-flower-headings;
    }
    &:nth-child(3) {
      color: $desert-sand-headings;
    }
    &:nth-child(4) {
      color: $red-centre;
    }
    &:nth-child(5) {
      color: $lorikeet-blue;
    }
  }
  .dates {
    grid-area: dates;
    color: $wattle-leaf-headings;
    margin: 0 10px;
    text-align: right;
    font-size: 15px;
    .location {
      font-weight: 400;
    }
  }

  @media only screen and (max-width: 960px) {
    grid-template:
      "logo curlyboi curlyboi" auto
      "nav nav dates" auto / min-content 1fr;
    #nav-logo img {
      height: 40px;
    }
    ul {
      font-size: 15px;
      li {
        padding: 10px;
      }
    }
    .dates {
      font-size: 12px;
      margin: 0 5px;
    }
  }

  @media only screen and (max-width: 599px) {
    grid-template:
      "logo curlyboi" 1fr
      "logo dates" min-content
      "nav nav" auto / min-content 1fr;
    .dates {
      font-size: 10px;
    }
  }

  /*
    @media only screen and (max-width: 1160px) {
        grid-template: "logo dates" auto
                        "nav nav" auto / auto auto;
        &.home {
            grid-template: "nav" auto "dates" auto / auto;
        }
        font-size: 20px;
        #nav-logo img {
            height: 45px;
        }
        .dates {
            font-size: 10px;
            margin: 10px;
        }
    }
    */
}

.lede {
  font-size: 1.333em;
}

.mailing-list-signup-form {
  --form-colour: #{$wattle-leaf};
  display: flex;
  > *:not(:last-child) {
    margin-right: 5px;
  }
  input,
  button {
    font-size: inherit;
    padding: 5px;
    border-radius: 5px;
  }
  input {
    flex: 1;
    border: 2px solid var(--form-colour);
    &:invalid {
      outline: 0 !important;
      box-shadow: none !important;
    }
    &:invalid:focus {
      border-color: $red-centre;
    }
    &:focus {
      border-color: $lorikeet-blue;
    }
  }
  button {
    background-color: var(--form-colour);
    border: 0;
    padding: 5px 15px;
    color: white;
    font-weight: bold;
    &:focus {
      outline: 0 !important;
      border: 2px solid $lorikeet-blue;
      padding: 3px 13px;
    }
  }
}

#covid-quote {
  font-size: (4/3) * 1em;
  margin-bottom: 2em;
  @media only screen and (min-width: 820px) {
    width: 800px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .source {
    font-size: 0.75em;
    display: grid;
    grid-template: "dash name" "dash title";
    grid-template-columns: max-content auto;
    .name {
      grid-area: name;
    }
    .title {
      grid-area: title;
      font-size: 0.75em;
    }
    &:before {
      content: "⸺";
      grid-area: dash;
      margin-right: 0.8ch;
    }
  }
}

main hr {
  background: hsla(0, 0, 0, 0.4);
  height: 2px;
  margin: 3em;
}

small {
  font-size: 0.75em;
}

// putting the footer at the bottom
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @include app-mode {
    height: 100vh;
  }
}
#content {
  flex-grow: 1;
}

footer {
  @include app-mode {
    display: none;
  }
  form {
    --form-colour: #777 !important;
  }
  display: grid;
  line-height: 1.3em;
  margin-top: 100px;
  color: #777;
  background-color: #eee;
  grid-template:
    "auspice video infra signup"
    "auspice video infra copyright";
  grid-template-columns: 1fr 1fr 1fr max-content;

  @media only screen and (max-width: 959px) {
    grid-template: "auspice" "video" "infra" "signup" "copyright";
  }

  section {
    margin: 20px;
    &.auspice {
      grid-area: auspice;
    }
    &.video {
      grid-area: video;
    }
    &.infra {
      grid-area: infra;
    }
    &.signup {
      grid-area: signup;
    }
    &.copyright {
      grid-area: copyright;
      align-self: end;
    }
    &.sponsor {
      h1 {
        border-bottom: 1px solid #777;
        padding-bottom: 10px;
      }

      img {
        height: 200px;
        width: calc(100% - 60px);
        object-fit: contain;
        margin: 30px;
      }
    }
  }
  h1 {
    font-size: 1.25em;
    font-weight: bold;
    font-family: $heading-font;
    margin-bottom: 10px;
  }
}

.center-content {
  display: flex;
  justify-content: center;
}

.btn {
  text-decoration: none;
  background-color: $wattle-leaf;
  color: white;
  font: $heading-font;
  display: inline-block;
  font-weight: bold;
  padding: 0.3em 1em;
  border-radius: 5px;

  &.btn-chonk {
    font-size: 4em * 1 / 3;
    border-radius: 5px * 4 / 3;
  }

  &.btn-smol {
    font-size: 1em * 3 / 4;
    border-radius: 5px * 3 / 4;
  }

  &:focus {
    border: 3px solid $wattle-flower;
    margin: -3px;
  }

  &:hover {
    background-color: darken($wattle-leaf, 10%);
  }
}

.ytembed {
  width: 100%;
}

@import "profile";

.sponsorlogo {
  max-height: 250px;
  max-width: 350px;
  margin: 20px;
  margin-top: 100px;
}

@import "schedule";

@import "backstage";

table {
  td,
  th {
    padding: 3px;
  }
  th {
    text-align: left;
  }
}

.content-warning {
  background-color: rgba($desert-sand, 0.25);
  color: darken($desert-sand, 40%);
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 0.75em;
  p:last-child {
    margin-bottom: 0;
  }
  margin: 20px 0;
}
